import React from "react";
import { MdWeb, SiApple, SiMicrosoft } from "react-icons/all";

const Hero = () => {
  return (
    <section className="relative">
      {/* Illustration behind hero content */}
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none"
        aria-hidden="true"
      >
        <svg
          width="1360"
          height="578"
          viewBox="0 0 1360 578"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-01"
            >
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#c084fc" offset="77.402%" />
              <stop stopColor="#a855f7" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Section header */}
          <div className="text-center pb-12 md:pb-16">
            <h1
              className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
              data-aos="zoom-y-out"
            >
              Achieve your goals With{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-400">
                Note Balance
              </span>
            </h1>
            <div className="max-w-3xl mx-auto">
              <p
                className="text-xl text-gray-600 mb-8"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                Multi-tasking is a myth. Single-tasking is a super power. Note
                Balance prompts you to plan your day around a single, clear goal
                — helping you carve out time to focus on the work that really
                matters.
              </p>
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                <div>
                  <a
                    href="https://github.com/muzudre/note-balance-release/releases/download/v1.1/balance-1.1.dmg"
                    className="flex mr-3 mt-3 w-full sm:w-48 h-14 bg-black  text-white border border-black hover:text-black hover:bg-white rounded-xl items-center justify-center"
                  >
                    <div className="mr-3">
                      <SiApple className="text-3xl" />
                    </div>
                    <div>
                      <div className="text-xs">Download .dmg</div>
                      <div className="text-2xl font-semibold font-sans -mt-1">
                        Mac OS
                      </div>
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    href="https://github.com/muzudre/note-balance-release/releases/download/v1.1/balance-1.1.exe"
                    className="flex mt-3 w-full sm:w-48 h-14 bg-transparent  text-black border border-black hover:text-white hover:bg-black rounded-xl items-center justify-center"
                  >
                    <div className="mr-3">
                      <SiMicrosoft className="text-3xl" />
                    </div>
                    <div>
                      <div className="text-xs">Download .exe</div>
                      <div className="text-2xl font-semibold font-sans -mt-1">
                        Windows
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Hero image */}
          <div>
            <div
              className="relative flex justify-center mb-8"
              data-aos="zoom-y-out"
              data-aos-delay="450"
            >
              <div className="flex flex-col justify-center">
                <img
                  className="mx-auto"
                  src={require("../../assets/1.png")}
                  width="768"
                  height="432"
                  alt="Hero"
                />
              </div>
              <button
                onClick={() =>
                  window.open("http://app.notebalance.com/", "_blank")
                }
                className="absolute top-full flex items-center transform -translate-y-1/2 bg-white hover:bg-gray-50 rounded-full font-medium group p-4 shadow-lg"
              >
                <MdWeb className="text-2xl" />
                <span className="ml-3 mr-3">Open on web</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
