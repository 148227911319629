import React from "react";
import { IoMdArrowRoundBack } from "react-icons/all";
import { Link } from "react-router-dom";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const NotFound = () => (
  <div className="flex flex-col min-h-screen overflow-hidden">
    {/*  Site header */}
    <Header />

    {/*  Page content */}
    <main className="flex-grow">
      <section className="relative">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/* Hero content */}
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            {/* Section header */}
            <div className="text-center pb-12 md:pb-16">
              <h1
                className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
                data-aos="zoom-y-out"
              >
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-purple-400">
                  404
                </span>{" "}
                <br />
                Not Found
              </h1>
              <div className="max-w-3xl mx-auto">
                <p
                  className="text-xl text-gray-600 mb-8"
                  data-aos="zoom-y-out"
                  data-aos-delay="150"
                >
                  The page you’re looking for doesn’t exist.
                </p>
              </div>
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                <div>
                  <Link
                    to={"/"}
                    className="text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 mr-2 mb-2"
                  >
                    <IoMdArrowRoundBack className="mr-2" />
                    Go back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    {/*  Site footer */}
    <Footer />
  </div>
);

export default NotFound;
