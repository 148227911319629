
import React from 'react';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Hero from '../../components/Hero/Hero';
import Features from '../../components/Features/Features';
import FeaturesBlocks from '../../components/FeatureBlocks/FeatureBlocks';

const Home = () => {
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            <Header />
            <main className="flex-grow">
                <Hero />
                <Features />
                <FeaturesBlocks />
            </main>
            <Footer />
        </div>
    );
}

export default Home;