import React from "react";
import { BsListCheck, BiBullseye } from "react-icons/all";

const Features = () => {
  return (
    <section className="bg-gray-100 h-auto">
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Don't let distractions hold you back
            </h1>
            <p className="text-xl text-gray-600">
              Note Balance is a app that gives you productivity superpowers by
              layering three proven techniques to boost focus.
            </p>
          </div>

          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-2 items-start md:max-w-2xl lg:max-w-none">
            <div className="relative flex flex-col items-center p-6 bg-white rounded-md shadow-xl">
              <BsListCheck className="w-16 h-16 p-1 -mt-1 mb-2 text-purple-600" />

              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">
                Start your day right
              </h4>
              <p className="text-gray-600 text-center">
                Note Balance prompts you to define one goal each day and then
                break your day down into sessions. This is proven to help you
                achieve undisturbed deep work and prioritize the goals that
                matter most to you.
              </p>
            </div>

            <div className="relative flex flex-col items-center p-6 bg-white rounded-md shadow-xl">
              <BiBullseye className="w-16 h-16 p-1 -mt-1 mb-2 text-purple-600" />
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">
                Stay focused for longer
              </h4>
              <p className="text-gray-600 text-center">
                Focus music, countdown timers, and regular breaks are proven to
                help you stay focused, but only when you have a clear plan and
                can work distraction-free. Note Balance offer focus enhancers
                while solving the underlying problem.
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </section>
  );
};

export default Features;
