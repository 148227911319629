import React from "react";
import { MdWeb } from "react-icons/all";

const FeaturesBlocks = () => {
  return (
    <section className="relative">
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Developers use Note Balance for deep work
            </h2>
            <p className="text-xl text-gray-600">
              To do your best work, you need uninterrupted time to solve complex
              problems. Deliver more code you’re proud of with Note Balance.
            </p>
          </div>
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-1 lg:grid-cols-1 items-start md:max-w-2xl lg:max-w-none">
            <div className="flex flex-col justify-center">
              <img
                className="mx-auto"
                src={require("../../assets/6.png")}
                alt="Hero"
              />
            </div>
          </div>
          <div className="text-center">
            <p className="mb-4">
              Note Balance users report feeling very productive 2.1X more often
              when using Note Balance. <br />
              <strong>
                What could you achieve with twice as many productive days?
              </strong>
            </p>
            <button className="items-center sm:w-48 w-full">
              <div
                onClick={() =>
                  window.open("http://app.notebalance.com/", "_blank")
                }
                className="flex mr-3 mt-3 w-full sm:w-48 h-14 bg-black  text-white border border-black hover:text-black hover:bg-white rounded-xl items-center justify-center"
              >
                <div className="mr-3">
                  <MdWeb className="text-3xl" />
                </div>
                <div>
                  <div className="text-xs">Open online</div>
                  <div className="text-2xl font-semibold font-sans -mt-1">
                    WEB
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesBlocks;
